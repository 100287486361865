export const environment = {
  production: false,
  API_URL: 'https://app.blipbillboards.com',
  OPERATOR_URL: 'https://operator.blipbillboards.com',
  STATIC_MAPS_KEY: 'AIzaSyAuhJgIG4Bbu3VR3upqCdEoyq4nMsCfsGA',
  TAG_MANAGER_ID: 'GTM-T9DZ7B9',
  PHOTOSHEET_URL: 'http://localhost:8000/gen/',
  PHOTOSHEET_COMPILE_URL: 'https://9or6ildv6e.execute-api.us-east-1.amazonaws.com/prod/convert',
  PHOTOSHEET_STATUS_URL: 'https://9or6ildv6e.execute-api.us-east-1.amazonaws.com/prod/getconversionstatus',
  CORNER_PIN_URL: 'https://9or6ildv6e.execute-api.us-east-1.amazonaws.com/prod/pinned.jpg',
  GAPI_CLIENT_ID: '749685095997-d76n2ntdq68o2f547a8ha59007h38vcr.apps.googleusercontent.com',
};
